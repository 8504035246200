import { CarryOutOutlined } from '@ant-design/icons';
import { GrowFormHeader, GrowModal } from '@sinecycle/growcomponents';
import { useQueryClient } from '@tanstack/react-query';
import { Skeleton, Tree } from 'antd';
import { DataNode, EventDataNode } from 'antd/es/tree';
import { GetRef } from 'antd/lib';
import { useCustomerFamilyTree, useGetCustomerDirectChild } from 'queries/Customers/customers';
import { useRef } from 'react';
import styled from 'styled-components';
import { CustomerTreeNode } from 'types/entities/customer';
import NodeTitleRenderer from './NodeTitleRenderer';

interface HierarchyTreeProps {
  isModalOpen: boolean;
  onClose: () => void;
  customerId: number;
}
const t = {
  familyTree: 'Family Tree',
};
type TreeRef = GetRef<typeof Tree<DataNode>>;

const TreeNode = Tree.TreeNode;
const StyledTree = styled(Tree)`
  white-space: nowrap;
  .ant-tree-treenode {
    overflow: visible !important;
    overflow-x: scroll;
  }
  .ant-tree-list-holder > div {
    overflow: visible !important;
    white-space: nowrap;
  }
  .ant-tree-node-content-wrapper {
    padding: 0 var(--space-8);
  }
`;
export default function ParentChildHierarchyTree(props: HierarchyTreeProps) {
  const { data: familyTree, isLoading: isFamilyDataLoading } = useCustomerFamilyTree(
    props.customerId
  );
  const treeRef = useRef<TreeRef>(null);
  const { mutateAsync: getCustomerDirectChild } = useGetCustomerDirectChild();
  const queryClient = useQueryClient();
  const ModalTitle = <GrowFormHeader mode="modal" onClose={props.onClose} title={t.familyTree} />;

  const updateTreeNode = (
    tree: CustomerTreeNode[],
    key: number,
    children: CustomerTreeNode[]
  ): CustomerTreeNode[] => {
    return tree.map((node) => {
      if (node.customer_id === key) {
        return node.children && node.children.length > 0
          ? node
          : {
              ...node,
              children: [...(node.children || []), ...children],
            };
      }
      if (node.children) {
        return { ...node, children: updateTreeNode(node.children, key, children) };
      }
      return node;
    });
  };

  const handleOnLoad = (treeNode: EventDataNode<DataNode>) => {
    return new Promise<void>((resolve) => {
      if (treeNode.children && treeNode.children.length > 0) {
        resolve();
        return;
      }
      const nodeKey = Number(treeNode.key);
      queryClient
        .fetchQuery(['customer-direct-child', nodeKey], () => getCustomerDirectChild(nodeKey))
        .then((data) => {
          queryClient.setQueriesData<CustomerTreeNode[]>(
            ['customer-family-tree'],
            (prevTreeData) => {
              if (!prevTreeData) return;
              return updateTreeNode(prevTreeData, nodeKey, data);
            }
          );

          resolve();
        })
        .catch(() => {
          resolve();
        });
    });
  };

  function renderTreeNode(node: CustomerTreeNode) {
    const CustomerName = <NodeTitleRenderer node={node} customerId={props.customerId} />;
    if (node.children && node.children.length > 0) {
      return (
        <TreeNode
          title={CustomerName}
          key={node.customer_id}
          isLeaf={!node.has_child}
          selectable={false}
          style={{ width: 'fit-content' }}
        >
          {node.children.map((child: CustomerTreeNode) => renderTreeNode(child))}
        </TreeNode>
      );
    }
    return (
      <TreeNode
        title={CustomerName}
        key={node.customer_id}
        isLeaf={!node.has_child}
        selectable={false}
        style={{ width: 'fit-content' }}
      />
    );
  }
  function scrollToSelectedTreeNode() {
    treeRef.current?.scrollTo({ key: String(props.customerId) });
  }

  return (
    <GrowModal
      open={props.isModalOpen}
      footer={null}
      onCancel={props.onClose}
      width={'640px'}
      title={ModalTitle}
      destroyOnClose
      centered
      height={550}
      closable={false}
      type="activities"
      styles={{ body: { padding: '16px', height: '540px' } }}
      afterOpenChange={(open) => {
        if (open) scrollToSelectedTreeNode();
      }}
    >
      <>
        {isFamilyDataLoading ? (
          <Skeleton />
        ) : (
          <StyledTree
            virtual
            height={500}
            ref={treeRef}
            showLine
            icon={<CarryOutOutlined />}
            defaultExpandedKeys={[String(props.customerId)]}
            loadData={handleOnLoad}
            blockNode={true}
            defaultSelectedKeys={[String(props.customerId)]}
          >
            {familyTree?.map((node: CustomerTreeNode) => {
              return renderTreeNode(node);
            })}
          </StyledTree>
        )}
      </>
    </GrowModal>
  );
}
