import { PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import {
  PersistedFilterKeys,
  PersistedFilterProps,
  PersistedFilterViews,
} from 'types/filter-view/common';
import { OpenInvoiceFilterParams } from 'types/filter-view/open-invoice';
import { localStore, sessionStore } from 'util/browser-storage';
import { intitalStateProps } from './invoice';
import { filterNormalizer } from './util';

const { getItem: getFromLocalStorage, setItem: setToLocalStorage } = localStore;
const { getItem: getFromSessionStorage, setItem: setToSessionStorage } = sessionStore;

const openInvoiceActions = {
  updateOpenInvoiceSavedFilterview(
    state: intitalStateProps,
    action: PayloadAction<intitalStateProps['openInvoiceSavedFilterviews']>
  ) {
    const locallyPersistedFilterIds = getFromLocalStorage<PersistedFilterProps>('filters');
    const sessionStore = getFromSessionStorage<PersistedFilterViews<OpenInvoiceFilterParams>>(
      PersistedFilterKeys.OPEN_INVOICES
    );

    const isFilterViewPresent =
      action.payload.findIndex((item) => item.id === locallyPersistedFilterIds?.OPEN_INVOICES) > -1;

    if (!isFilterViewPresent && locallyPersistedFilterIds && sessionStore) {
      //removing both the entries from browser store
      delete sessionStore[`${locallyPersistedFilterIds?.OPEN_INVOICES}`];
      delete locallyPersistedFilterIds.OPEN_INVOICES;

      setToLocalStorage('filters', locallyPersistedFilterIds);
      setToSessionStorage(PersistedFilterKeys.OPEN_INVOICES, sessionStore);
    }

    state.openInvoiceSavedFilterviews = action.payload;
  },

  updateOpenInvoiceCurrentFilterview(
    state: intitalStateProps,
    action: PayloadAction<{ id?: string; reset?: boolean } | undefined>
  ) {
    state.openCurrentPage = 1;
    const locallyPersistedFilterIds = getFromLocalStorage<PersistedFilterProps>('filters') ?? {};
    const sessionStore = getFromSessionStorage<PersistedFilterViews<OpenInvoiceFilterParams>>(
      PersistedFilterKeys.OPEN_INVOICES
    );

    if (action && action.payload?.id && locallyPersistedFilterIds) {
      const item: PersistedFilterProps = {
        ...locallyPersistedFilterIds,
        OPEN_INVOICES: action.payload.id,
      };

      setToLocalStorage('filters', item);
    }

    const savedFilterViews = state.openInvoiceSavedFilterviews;
    const currentFilterViewId =
      action.payload?.id ?? locallyPersistedFilterIds?.OPEN_INVOICES ?? savedFilterViews[0].id;
    const currentFilter =
      savedFilterViews.find((filterView) => {
        return filterView.id === currentFilterViewId;
      }) ?? savedFilterViews[0];

    //When applying filter, giving preference to session store
    const sessionFilters = sessionStore && sessionStore[`${currentFilterViewId}`];
    const filters = action.payload?.reset
      ? currentFilter.invoice_filter
      : sessionFilters ?? currentFilter.invoice_filter;
    state.openInvoiceCurrentFilterView = {
      ...currentFilter,
      invoice_filter: filterNormalizer(filters),
    };
  },

  updateOpenInvoiceCurrentFilters(
    state: intitalStateProps,
    action: PayloadAction<OpenInvoiceFilterParams>
  ) {
    //preventing duplicate calls through this.
    state.openCurrentPage = 1;

    const currentFilter = { ...action.payload };
    const filterViewId = state.openInvoiceCurrentFilterView?.id;
    const shouldUpdate =
      currentFilter &&
      Boolean(Object.keys(currentFilter).length) &&
      !isEqual(currentFilter, state.openInvoiceCurrentFilterView?.invoice_filter);

    const sessionStore =
      getFromSessionStorage<PersistedFilterViews<OpenInvoiceFilterParams>>(
        PersistedFilterKeys.OPEN_INVOICES
      ) ?? {};

    if (shouldUpdate) {
      const updatedState = {
        ...state.openInvoiceCurrentFilterView,
        invoice_filter: currentFilter,
      } as intitalStateProps['openInvoiceCurrentFilterView'];

      state.openInvoiceCurrentFilterView = updatedState;

      //updating the session storage while updating the currentfilter
      if (sessionStore && filterViewId) {
        sessionStore[`${filterViewId}`] = currentFilter;

        setToSessionStorage(PersistedFilterKeys.OPEN_INVOICES, sessionStore);
      }
    }
  },

  updateOpenInvoiceQuerParams(
    state: intitalStateProps,
    action: PayloadAction<OpenInvoiceFilterParams | undefined>
  ) {
    state.openInvoiceFilterQueryParams = action.payload;
  },

  updateTotalCustomerCount(state: intitalStateProps, action: PayloadAction<number>) {
    state.totalCustomerCount = action.payload;
  },

  resetOpenInvoiceState(state: intitalStateProps) {
    state.openInvoiceSavedFilterviews = [];
    state.openInvoiceCurrentFilterView = {} as intitalStateProps['openInvoiceCurrentFilterView'];
  },

  updateOpenCurrentPage(state: intitalStateProps, action: PayloadAction<number>) {
    state.openCurrentPage = action.payload;
  },
  resetOpenCurrentPage(state: intitalStateProps) {
    state.openCurrentPage = 1;
  },
};

export { openInvoiceActions };
