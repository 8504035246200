import { FilterViews } from 'components/FilterViews';
import { FilterView } from 'components/FilterViews/type';
import produce from 'immer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  closedInvoiceCurrentFilterViewSelector,
  closedInvoiceSavedFilterViewsSelector,
  updateClosedInvoiceCurrentFilterview,
  updateClosedInvoiceSavedFilterview,
} from 'store/invoice-v2/invoice';
import { ClosedInvoiceFilterParams } from 'types/filter-view/closed-invoice';
import { InvoiceFilterView } from 'types/filter-view/common';
import { DownloadReports } from '../DownloadReports';

export function ClosedInvoiceFilterViews() {
  const dispatch = useDispatch();

  /** selectors */
  const currentFilterView = useSelector(closedInvoiceCurrentFilterViewSelector, shallowEqual);
  const savedFilterViews = useSelector(closedInvoiceSavedFilterViewsSelector);

  function updateCurrentView(
    filterViews: InvoiceFilterView<ClosedInvoiceFilterParams>,
    reset?: boolean
  ) {
    dispatch(updateClosedInvoiceCurrentFilterview({ id: filterViews.id, reset }));
  }

  function saveFilterViewCallback(filterViews: InvoiceFilterView<ClosedInvoiceFilterParams>) {
    const updatedViews = produce(savedFilterViews, (draft) => {
      const isExists = draft.findIndex((view) => view.id === filterViews.id);
      draft[isExists] = filterViews;
    });
    dispatch(updateClosedInvoiceSavedFilterview(updatedViews));
    dispatch(updateClosedInvoiceCurrentFilterview({ id: filterViews.id }));
  }

  function deleteFilterViewCallback(filterId: string) {
    const filterViews = savedFilterViews.filter((filterView) => filterView.id !== filterId);
    dispatch(updateClosedInvoiceSavedFilterview(filterViews));
    dispatch(updateClosedInvoiceCurrentFilterview());
  }
  function saveAsFilterViewCallBack(filterViews: InvoiceFilterView<ClosedInvoiceFilterParams>) {
    const updatedViews = produce(savedFilterViews, (draft) => {
      draft.push(filterViews);
    });
    dispatch(updateClosedInvoiceSavedFilterview(updatedViews));
    dispatch(updateClosedInvoiceCurrentFilterview({ id: filterViews.id }));
  }

  const FilterViewList = (
    <FilterViews<InvoiceFilterView<ClosedInvoiceFilterParams>, ClosedInvoiceFilterParams>
      totalSavedViews={savedFilterViews as unknown as FilterView<ClosedInvoiceFilterParams>[]}
      currentView={currentFilterView as unknown as FilterView<ClosedInvoiceFilterParams>}
      filterType="invoice_filter"
      entity="closed-invoices"
      filterViewLoading={currentFilterView && currentFilterView.id ? false : true}
      updatedFilterValue={currentFilterView?.invoice_filter}
      deleteFilterViewCallBack={deleteFilterViewCallback}
      saveAsFilterViewCallBack={saveAsFilterViewCallBack}
      saveFilterViewCallBack={saveFilterViewCallback}
      updateFilterViewCallBack={updateCurrentView}
      onResetFilterViewCallBack={(data) => updateCurrentView(data, true)}
      components={<DownloadReports />}
    />
  );

  return FilterViewList;
}
