import { PayloadAction } from '@reduxjs/toolkit';
import { head, isEqual } from 'lodash';
import {
  PersistedFilterKeys,
  PersistedFilterProps,
  PersistedFilterViews,
} from 'types/filter-view/common';
import { DraftInvoiceFilterParams } from 'types/filter-view/draft-invoice';
import { localStore, sessionStore } from 'util/browser-storage';
import { intitalStateProps } from './invoice';
import { filterNormalizer } from './util';

const { getItem: getFromLocalStorage, setItem: setToLocalStorage } = localStore;
const { getItem: getFromSessionStorage, setItem: setToSessionStorage } = sessionStore;

const draftInvoiceActions = {
  updateDraftInvoiceSavedFilterview(
    state: intitalStateProps,
    action: PayloadAction<intitalStateProps['draftInvoiceSavedFilterviews']>
  ) {
    const locallyPersistedFilterIds = getFromLocalStorage<PersistedFilterProps>('filters');
    const sessionStore = getFromSessionStorage<PersistedFilterViews<DraftInvoiceFilterParams>>(
      PersistedFilterKeys.DRAFT_INVOICES
    );

    const isFilterViewPresent =
      action.payload.findIndex((item) => item.id === locallyPersistedFilterIds?.DRAFT_INVOICES) >
      -1;

    if (!isFilterViewPresent && locallyPersistedFilterIds && sessionStore) {
      //removing both the entries from browser store
      delete sessionStore[`${locallyPersistedFilterIds?.DRAFT_INVOICES}`];
      delete locallyPersistedFilterIds.DRAFT_INVOICES;

      setToLocalStorage('filters', locallyPersistedFilterIds);
      setToSessionStorage(PersistedFilterKeys.DRAFT_INVOICES, sessionStore);
    }

    state.draftInvoiceSavedFilterviews = action.payload;
  },

  updateDraftInvoiceCurrentFilterview(
    state: intitalStateProps,
    action: PayloadAction<{ id?: string; reset?: boolean } | undefined>
  ) {
    state.draftCurrentPage = 1;
    const locallyPersistedFilterIds = getFromLocalStorage<PersistedFilterProps>('filters') ?? {};
    const sessionStore = getFromSessionStorage<PersistedFilterViews<DraftInvoiceFilterParams>>(
      PersistedFilterKeys.DRAFT_INVOICES
    );

    if (action && action.payload?.id && locallyPersistedFilterIds) {
      const item: PersistedFilterProps = {
        ...locallyPersistedFilterIds,
        DRAFT_INVOICES: action.payload.id,
      };

      setToLocalStorage('filters', item);
    }

    const savedFilterViews = state.draftInvoiceSavedFilterviews;
    const currentFilterViewId =
      action.payload?.id ?? locallyPersistedFilterIds?.DRAFT_INVOICES ?? head(savedFilterViews)?.id;
    const currentFilter =
      savedFilterViews.find((filterView) => {
        return filterView.id === currentFilterViewId;
      }) ?? savedFilterViews[0];

    //When applying filter, giving preference to session store
    const sessionFilters = sessionStore && sessionStore[`${currentFilterViewId}`];
    const filters = action.payload?.reset
      ? currentFilter.invoice_filter
      : sessionFilters ?? currentFilter.invoice_filter;
    state.draftInvoiceCurrentFilterView = {
      ...currentFilter,
      invoice_filter: filterNormalizer(filters),
    };
  },

  updateDraftInvoiceCurrentFilters(
    state: intitalStateProps,
    action: PayloadAction<DraftInvoiceFilterParams>
  ) {
    //preventing duplicate calls through this.
    state.draftCurrentPage = 1;

    const currentFilter = { ...action.payload };
    const filterViewId = state.draftInvoiceCurrentFilterView?.id;
    const shouldUpdate =
      currentFilter &&
      Boolean(Object.keys(currentFilter).length) &&
      !isEqual(currentFilter, state.draftInvoiceCurrentFilterView?.invoice_filter);

    const sessionStore =
      getFromSessionStorage<PersistedFilterViews<DraftInvoiceFilterParams>>(
        PersistedFilterKeys.DRAFT_INVOICES
      ) ?? {};

    if (shouldUpdate) {
      const updatedState = {
        ...state.draftInvoiceCurrentFilterView,
        invoice_filter: currentFilter,
      } as intitalStateProps['draftInvoiceCurrentFilterView'];

      state.draftInvoiceCurrentFilterView = updatedState;

      //updating the session storage while updating the currentfilter
      if (sessionStore && filterViewId) {
        sessionStore[`${filterViewId}`] = currentFilter;

        setToSessionStorage(PersistedFilterKeys.DRAFT_INVOICES, sessionStore);
      }
    }
  },

  updateDraftInvoiceQuerParams(
    state: intitalStateProps,
    action: PayloadAction<DraftInvoiceFilterParams | undefined>
  ) {
    state.draftInvoiceFilterQueryParams = action.payload;
  },

  updateTotalCustomerCount(state: intitalStateProps, action: PayloadAction<number>) {
    state.totalCustomerCount = action.payload;
  },

  resetDraftInvoiceState(state: intitalStateProps) {
    state.draftInvoiceSavedFilterviews = [];
    state.draftInvoiceCurrentFilterView = {} as intitalStateProps['draftInvoiceCurrentFilterView'];
  },

  updateDraftCurrentPage(state: intitalStateProps, action: PayloadAction<number>) {
    state.draftCurrentPage = action.payload;
  },
  resetDraftCurrentPage(state: intitalStateProps) {
    state.draftCurrentPage = 1;
  },
};

export { draftInvoiceActions };
