import { Spin } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { ErrorBoundaryWrapper } from 'components/Common/ErrorBoundary';
import { Privileged } from 'components/HigherOrderComponent/Privileged';
import Helmet from 'lib/helmet';
import { matchPath, useLocation } from 'lib/router';
import { useEffect, useMemo } from 'react';
import { AppPage, pageInfo } from './constants/page-info';
import { useBreadCrumbs } from './layout/Protected/LayoutContext';

type PageTitleObj = { header?: string; doctitle: string | string[] };
type PageTitle = string | PageTitleObj;

function isPageTitleObj(title?: PageTitle): title is PageTitleObj {
  return typeof title === 'object';
}

interface PageContainerConfig {
  updateBreadcrumbs: boolean;
}

const defaultPageContainerConfig: PageContainerConfig = {
  updateBreadcrumbs: true,
};

interface PageContainerProps {
  page: keyof typeof AppPage;
  title?: PageTitle;
  children: React.ReactNode;
  config?: Partial<PageContainerConfig>;
}
export function PageContainer({
  page,
  title,
  children,
  config: passedConfig = {},
}: PageContainerProps) {
  const location = useLocation();
  const { addBreadcrumb } = useBreadCrumbs();
  const config = { ...defaultPageContainerConfig, ...passedConfig };

  const pageDeets = pageInfo(page);
  const { privileges, title: pageTitle, path } = pageDeets;

  const titleToSet = useMemo(
    () => (isPageTitleObj(title) ? title.header ?? pageTitle : title ?? pageTitle),
    [pageTitle, title]
  );

  const { fromSidebar } = (location.state ?? { fromSidebar: false }) as {
    fromSidebar: boolean;
  };

  useEffect(() => {
    const matchedPath = matchPath(path, location.pathname);
    if (matchedPath && config.updateBreadcrumbs) {
      addBreadcrumb(
        { appPage: page, path: location.pathname, title: titleToSet },
        { reset: fromSidebar }
      );
    }
  }, [
    addBreadcrumb,
    location.pathname,
    page,
    path,
    titleToSet,
    config.updateBreadcrumbs,
    fromSidebar,
  ]);

  // useEffect(() => {
  //   const documentTitle = isPageTitleObj(title) ? title.doctitle : title ?? pageTitle;
  //   document.title = documentTitle;
  // }, [pageTitle, setPageHeader, title]);

  const documentTitle = isPageTitleObj(title) ? title.doctitle : title ?? pageTitle;

  return (
    <>
      {documentTitle && <Helmet title={documentTitle} />}
      <ErrorBoundaryWrapper>
        <Privileged privileges={privileges} shallowCheck showFallBack>
          {children}
        </Privileged>
      </ErrorBoundaryWrapper>
    </>
  );
}

export function PageSuspenseFallback() {
  return (
    <Flex justify="center" style={{ minHeight: '80vh' }} align="center">
      <Spin size="large" />
    </Flex>
  );
}
