import { LayoutHeader } from 'components/BaseComponents/Layout/AntLayout';
import styled from 'styled-components';
// enabled during xmas season only
// import XmasHeader from 'assets/images/app-header-christmas.webp';
export const StyledLayoutHeader = styled(LayoutHeader)`
  border-bottom: 1px solid var(--gray-5);
  height: var(--app-header-height);
  box-shadow: var(--shadow-1);
  padding-inline: var(--space-24);
  background-color: var(--gray-1);
  display: flex;
  height: auto;
  min-height: 64px;
  /* enable for chritmas theme */
  /* background-size: cover;
  background-position: right; */
  .header-text {
    margin-left: var(--space-24);
  }
  .header-right-items {
    text-align: right;
  }
  .ant-select {
    width: 388px !important;
  }
  .ant-avatar {
    margin-top: -2px;
  }
`;
