import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { amountFormats } from '@sinecycle/growcomponents';
import { Col, Popover, Row, Spin } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import EmptyChart, { getIsEmpty } from 'components/Common/Charts/EmptyChart';
import PieChart from 'components/Common/Charts/Pie';
import { PieChartEventData } from 'components/Common/Charts/Pie/type';
import { GenericPlot, PieConfigs } from 'components/Common/Charts/types';
import Legend from 'components/Common/Legend';
import { NumberFormat } from 'components/Common/NumberFormat/NumberFormat';
import { usePageNavigate } from 'lib/router';
import { sum } from 'lodash';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentViewAging } from 'store/aging/aging';
import { localeSelector } from 'store/authentication/authentication';
import { formatCurrency } from 'util/number-formatter';
import ToolTip from './ToolTip';
import { ChartRef, CollectionStatusData } from './type';

const t = {
  warningText: 'Unassigned Customers',
};

const infoIcon = (
  <FontAwesomeIcon
    icon={['far', 'circle-info']}
    color={'var(--geekblue-6)'}
    style={{
      fontSize: 'var(--fs-14)',
      marginLeft: 'var(--space-4)',
      cursor: 'pointer',
    }}
  />
);

function CollectionStatusChart(props: Omit<DashboardComponentProps, 'filterParams'>) {
  const plotRef = useRef<GenericPlot<CollectionStatusData> | null>(null);
  const navigate = usePageNavigate();
  const chartRef = useRef<ChartRef>();
  const { collectionStatuses } = props;
  const locale = useSelector(localeSelector);
  const dispatch = useDispatch();
  function handleCollectionStatusData(): CollectionStatusData[] {
    if (collectionStatuses && Boolean(collectionStatuses.list.length)) {
      const totalAmount = sum(
        collectionStatuses.list.map((statues) => statues.outstanding.amount.value)
      );
      return collectionStatuses.list.map((status) => {
        return {
          type: status.status.label,
          value: status.outstanding.amount.value,
          valueObject: status,
          color: status.status.color_code,
          totalAmount,
        };
      });
    }
    return [];
  }

  function handleOnReady(plot: GenericPlot<CollectionStatusData>) {
    plot.on('plot:click', handlePieClick);
    plotRef.current = plot;
  }

  const collectionStatusData: CollectionStatusData[] = useMemo(handleCollectionStatusData, [
    collectionStatuses,
  ]);

  function handlePieClick(event: PieChartEventData<CollectionStatusData>) {
    event.gEvent.stopPropagation();
    event.gEvent.preventDefault();
    if (!event.data) return;
    dispatch(updateCurrentViewAging({}));
    const status = event.data.data.valueObject.status.id;
    const metaActivityData = new URLSearchParams({
      sort_col: 'TOTAL_DUE_AMOUNT',
      sort_by: 'DESC',
    });
    navigate({
      appPage: 'AR_AGING',
      searchParams: `${metaActivityData}&coll_status=[${status}]`,
      state: {
        addPrimaryPathAsChildPath: true,
      },
    });
  }

  const pieConfig: Omit<PieConfigs<CollectionStatusData>, 'data'> = {
    className: 'collection-status-chart',
    appendPadding: [16],
    renderer: 'canvas',
    angleField: 'value',
    colorField: 'color',
    radius: 0.9,
    label: false,
    width: 100,
    color: ({ color }) => color,
    onReady: handleOnReady,
    supportCSSTransform: true,
    tooltip: {
      customContent: (title, content) => {
        return (
          Boolean(content.length) && <ToolTip title={title} content={content} locale={locale} />
        );
      },
    },
    legend: false,
    style: {
      cursor: 'pointer',
      height: '200px',
      width: '70%',
    },
  };

  const LoadingTemplate = <Spin />;

  const UnassignedCustomersCount = (
    <Flex
      style={{
        background: 'var(--gray-2)',
        borderRadius: 'var(--space-4)',
        padding: 'var(--space-8)',
      }}
      gap={'var(--space-4)'}
      direction="column"
    >
      <Flex align="center" gap={'var(--space-4)'}>
        <div>{infoIcon}</div>
        <GrowText size="var(--fs-12)" color="var(--gray-9)">
          {t.warningText}
        </GrowText>
      </Flex>
      <Row align="middle" gutter={[4, 4]} wrap={false}>
        <Col>
          <NumberFormat
            value={collectionStatuses?.summary.un_assigned_customer.customer_count ?? 0}
            textProps={{ fs: '16', strong: true }}
          />
        </Col>
        <GrowText size="var(--fs-16)" color="var(--gray-9)">
          of
        </GrowText>
        <Col>
          <NumberFormat
            value={collectionStatuses?.summary.total_customer_count ?? 0}
            textProps={{ fs: '16' }}
          />
        </Col>
      </Row>
    </Flex>
  );

  const WarningBoxContent = () => {
    const infoDiv = (label: string, value: any) => (
      <Flex direction="column" gap="var(--space-2)">
        <GrowText size={'var(--fs-12)'} color="var(--gray-7)">
          {label}
        </GrowText>

        <GrowText color="var(--gray-9)">{value}</GrowText>
      </Flex>
    );

    const receivables = `${formatCurrency(
      collectionStatuses?.summary.un_assigned_customer.amount.value,
      {
        currency: collectionStatuses?.summary.un_assigned_customer.amount.currency ?? '',
        locale,
      }
    )}`;

    const customerCount = amountFormats.number(
      collectionStatuses?.summary.un_assigned_customer.customer_count,
      { locale }
    );
    const invoiceCount = amountFormats.number(
      collectionStatuses?.summary.un_assigned_customer.invoice_count,
      { locale }
    );

    return (
      <div>
        <Flex>
          <div style={{ marginRight: 'var(--space-8)' }}> {infoIcon}</div>
          <GrowText strong>{t.warningText}</GrowText>
        </Flex>
        <Flex direction="column" gap="var(--space-8)">
          {infoDiv('Receivables', receivables)}
          {infoDiv('Customers', customerCount)}
          {infoDiv('Invoices', invoiceCount)}
        </Flex>
      </div>
    );
  };

  const legendPropData = useMemo(
    () =>
      collectionStatusData?.map((item) => {
        return {
          label: item.type,
          color: item.valueObject.status.color_code,
          disable: !item.valueObject.outstanding.invoice_count,
          id: item.valueObject.status.id,
        };
      }),
    [collectionStatusData]
  );

  const currentLegends = legendPropData.map((item) => item.id);

  function handleOnClickLegend(type: number) {
    const plot = plotRef.current;

    if (!plot) return;
    const exist = currentLegends.findIndex((t) => t === type);
    if (exist === -1) {
      currentLegends.push(type);
    } else {
      currentLegends.splice(exist, 1);
    }
    plot.chart.filter('type', (value, item) => {
      return currentLegends.includes((item as CollectionStatusData).valueObject.status.id);
    });
    plot.chart.render(true);
  }

  const tooltipTextProp = 'No customers mapped';

  const isEmpty = getIsEmpty(collectionStatusData, ['value']);

  return (
    <>
      <Flex direction="row" gap={'var(--space-4)'} style={{ width: '100%' }} align="center">
        <EmptyChart isEmpty={isEmpty}>
          <PieChart
            {...pieConfig}
            data={collectionStatusData}
            chartRef={chartRef}
            loadingTemplate={LoadingTemplate}
          />
        </EmptyChart>
        <Flex direction={'column'} gap={'var(--space-16)'}>
          <div style={{ width: '200px' }}>
            {legendPropData.length ? (
              <div className="legend-list">
                <Legend
                  data={legendPropData}
                  handleOnClickLegend={handleOnClickLegend}
                  tooltipTextProp={tooltipTextProp}
                />
              </div>
            ) : null}
          </div>
        </Flex>
      </Flex>
      <div>
        {collectionStatuses && (
          <Popover content={WarningBoxContent} trigger="hover" placement="right">
            {UnassignedCustomersCount}
          </Popover>
        )}
      </div>
    </>
  );
}

export default CollectionStatusChart;
