import { FilterView } from '@sinecycle/growcomponents';
import { useMutation } from '@tanstack/react-query';
import { SearchParamsOption } from 'ky';
import { omitBy } from 'lodash';
import { InvoiceFilterView } from 'types/filter-view/common';
import { OpenInvoiceFilterParams } from 'types/filter-view/open-invoice';
import { FilterViewInterface, TFilterViews } from '../store/aging/types';
import ky from './ky';

const baseUrl = 'filters';

export async function saveCurrentFilterView<T>(data: Partial<FilterView<T>>, type: string) {
  if (data.payments_report_filter) {
    data.payments_report_filter = {
      ...omitBy(data.payments_report_filter, (item) => Array.isArray(item) && item.length === 0),
    } as T;
  }

  return await ky.post(`${baseUrl}/${type}`, { json: data }).json<FilterView<T>>();
}

export async function getSavedFilterViews(type: string, params?: SearchParamsOption) {
  return (await ky
    .get(`${baseUrl}/${type}`, { searchParams: params })
    .json()) as FilterViewInterface<TFilterViews>[];
}

export function deleteSavedFilterViews(id: string) {
  return ky.delete(`${baseUrl}/${id}`).json();
}

//Latest URL with TYPES
export async function saveFilterView<T extends OpenInvoiceFilterParams>(
  data: InvoiceFilterView<T>,
  type: string
) {
  return (await ky.post(`${baseUrl}/${type}`, { json: data }).json()) as InvoiceFilterView<T>;
}

interface useSaveFilterViewProps<T> {
  data: Partial<FilterView<T>>;
  type: string;
}

export function useSaveFilterView<T>() {
  return useMutation<FilterView<T>, void, useSaveFilterViewProps<T>>({
    mutationFn: ({ data, type }: useSaveFilterViewProps<T>) => saveCurrentFilterView(data, type),
  });
}

export function useDeleteFilterView<T>() {
  return useMutation({
    mutationFn: (id: string) => ky.delete(`${baseUrl}/${id}`).json<void>(),
  });
}
