import { multiCurrencyAmount } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import { isInvoiceAccessible } from 'util/getIsAccessible';
import { InvoicesColumns } from './InvoicesColumn';

export const mapInvoicesColumnPredicate = (invoice: InvoiceBasicDetails): InvoicesColumns => {
  const isAccessible = isInvoiceAccessible(invoice);
  return {
    id: invoice.id,
    title: invoice.invoice_no,
    amount: invoice.balance_due_amount_base_currency,
    isAccessible,
    currency: invoice.currency,
  };
};

export const mapInvoicesBaseCurrencyPredicate = (
  invoice: InvoiceBasicDetails,
  currency: string
): InvoicesColumns => {
  const isAccessible = isInvoiceAccessible(invoice);
  return {
    id: invoice.id,
    title: invoice.invoice_no,
    amount: invoice.balance_due_amount_base_currency,
    isAccessible,
    currency,
  };
};

export const mapInvoicesTransactionalCurrencyPredicate = (
  invoice: InvoiceBasicDetails,
  currency: string
) => {
  const isAccessible = isInvoiceAccessible(invoice);
  const invoiceAmount = multiCurrencyAmount(
    invoice.amount_applied!,
    currency,
    invoice.currency,
    invoice.amount_applied_base_currency!
  );
  return {
    id: invoice.id,
    title: invoice.invoice_no,
    amount: invoiceAmount,
    isAccessible,
    currency,
  };
};
