import { PlotEvent } from '@ant-design/plots';
import ColumnChart from 'components/Common/Charts/Column';
import { ColumnConfigProps, Datum } from 'components/Common/Charts/types';
import { bucketColorChart } from 'components/ReceivableSettings/ReceivableDisplay/constant';
import { usePageNavigate } from 'lib/router';
import { sum } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentViewAging } from 'store/aging/aging';
import { baseCurrencySelector, localeSelector } from 'store/authentication/authentication';
import { AgingBucket, AgingBucketDTO } from 'types/entities/aging';
import { NonUndefined } from 'types/utils/utils';
import { formatCurrency } from 'util/number-formatter';
import Tooltip from './Tooltip/tooltip';

type ColumChartProps = Parameters<
  NonUndefined<React.ComponentProps<typeof ColumnChart>['onReady']>
>['0'];
interface AgingProps {
  agingBuckets?: AgingBucket[] | AgingBucketDTO[];
  hideYaxisTitle?: Boolean;
  hideXaxisTitle?: Boolean;
  hidePercentage?: Boolean;
  customTitle?: string;
  height: number;
  enableOnClick?: Boolean;
  padding: number[];
  formatter: (datum: Datum) => string;
}
function Aging(props: AgingProps) {
  const { agingBuckets } = props;
  const baseCurrency = useSelector(baseCurrencySelector);
  const navigate = usePageNavigate();
  const [splitLabel, setSplitLabel] = useState(false);
  const dispatch = useDispatch();
  const splitLabelLimit = 6;
  const locale = useSelector(localeSelector);
  const formattedData = useMemo(processChartData, [agingBuckets, baseCurrency, locale]);

  const gray9 = '#262626';
  const gray7 = '#8C8C8C';
  const t = {
    title: 'Overdue Invoices by Aging Buckets',
    xAxis: 'Days',
    yAxis: 'Amount (%)',
  };

  function processChartData() {
    const totalAmount = sum(agingBuckets?.map((agingBucket) => agingBucket.amount.amount.value));
    return (agingBuckets ?? []).map((agingBucket, index) => {
      const label =
        index + 1 === agingBuckets?.length
          ? `> ${agingBucket.bucket_from - 1}`
          : `${agingBucket.bucket_from}-${agingBucket.bucket_to}`;
      const options = {
        currency: agingBucket.amount.amount.currency ?? baseCurrency ?? 'USD',
        locale,
      };
      const formattedAmount = isNaN(agingBucket.amount.amount.value)
        ? '-'
        : formatCurrency(agingBucket.amount.amount.value, options, { notation: 'compact' });

      if (label.length >= splitLabelLimit) setSplitLabel(true);
      return {
        value: agingBucket.amount.amount.value,
        age: label,
        invoices: agingBucket.amount.invoice_count,
        bucket_from: agingBucket.bucket_from,
        bucket_to: agingBucket.bucket_to,
        type: bucketColorChart[index],
        totalAmount,
        currency: agingBucket.amount.amount.currency,
        formattedAmount: formattedAmount,
      };
    });
  }
  function handleOnReady(plot: ColumChartProps) {
    plot.on('plot:click', handleColumnClick);
  }

  function handleColumnClick(event: PlotEvent) {
    event.gEvent.preventDefault();
    event.gEvent.stopPropagation();
    if (event.data) {
      dispatch(updateCurrentViewAging({}));
      const metaActivityData = new URLSearchParams({
        sort_col: 'TOTAL_DUE_AMOUNT',
        sort_by: 'DESC',
        due_days_min: `${event.data.data.bucket_from}`,
        due_days_max: `${event.data.data.bucket_to}`,
        due_days_range: `${event.data.data.age} days`,
      });
      navigate({
        appPage: 'AR_AGING',
        searchParams: metaActivityData,
        state: {
          addPrimaryPathAsChildPath: true,
        },
      });
    }
  }

  const chartData = formattedData;
  const chartConfig: Omit<ColumnConfigProps, 'data'> = {
    className: 'aging-chart',
    renderer: 'canvas',
    columnWidthRatio: 1,
    xField: 'age',
    yField: 'value',
    legend: false,
    seriesField: 'type',
    color: ({ type }) => type,
    onReady: props.enableOnClick ? handleOnReady : undefined,
    xAxis: {
      tickLine: {
        style: {
          opacity: 0,
        },
      },
      title: props.hideXaxisTitle
        ? null
        : {
            text: t.xAxis,
            offset: 55,
            position: 'middle',
            style: {
              fill: gray7,
            },
          },
      label: {
        style: {
          fontSize: 12,
          lineHeight: 15,
          fill: gray9,
        },
        formatter: (value: string) => {
          if (splitLabel && value.includes('-') && formattedData.length > 10) {
            const arr: string[] = value.split('-');
            return ` ${arr[0]}-
${arr[1]}`;
          }
          return value;
        },
      },
    },
    yAxis: {
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
      title: props.hideYaxisTitle
        ? null
        : {
            text: t.yAxis,
            offset: 15,
            position: 'middle',
            style: {
              fill: gray7,
            },
          },
      label: {
        style: {
          opacity: 0,
        },
        offsetX: 100,
      },
    },
    height: props.height,
    tooltip: {
      customContent: (title, content) => {
        return (
          Boolean(content.length) && (
            <Tooltip
              content={content}
              locale={locale}
              hidePercentage={props.hidePercentage}
              customTitle={props.customTitle}
            />
          )
        );
      },
    },
    label: {
      position: 'top',
      offsetY: 10,
      style: {
        fill: gray9,
      },

      formatter: props.formatter,
    },
    padding: props.padding,
  };

  return <ColumnChart {...chartConfig} data={chartData} />;
}

export default React.memo(Aging);
