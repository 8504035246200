import { Flex, FlexBoxChild } from 'components/BaseComponents/Layout/Flex';
import ClosedInvoicesStatements from 'components/CustomerDetails/Body/Statements/ClosedInvoicesStatements';
import FiltersLoader from 'components/FilterViews/Loader/FiltersLoaderLoader';
import FilterStrip from 'components/FilterViews/Strips/FilterStrips';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSavedFilterViews } from 'services/filter-view';
import {
  closedInvoiceCurrentFilterSelector,
  resetClosedInvoiceState,
  updateClosedCurrentPage,
  updateClosedInvoiceCurrentFilters,
  updateClosedInvoiceCurrentFilterview,
  updateClosedInvoiceSavedFilterview,
} from 'store/invoice-v2/invoice';
import { ClosedInvoicesListFilters } from 'store/invoice/type';
import { ClosedInvoiceFilterParams } from 'types/filter-view/closed-invoice';
import { InvoiceFilterView } from 'types/filter-view/common';

import { Divider } from 'antd';
import { closedCurrentPage } from 'store/invoice-v2/closed-invoice-selectors';
import { ClosedInvoiceFilterViews } from './ClosedInvoiceFilterViews';
import { ClosedInvoiceFilterHeader } from './ClosedInvoiceFilters';

export function ClosedInvoiceList() {
  const currentFilter = useSelector(closedInvoiceCurrentFilterSelector);
  const dispatch = useDispatch();
  const hasSavedFilterUpdated = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentPage = useSelector(closedCurrentPage);
  const hasActOnClosedInvoiceAccess = usePrivilegeStatus([
    ERolesAndPrivileges.ACT_CLOSED_INVOICES_INVOICE_LIST,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetClosedInvoiceState());
    };
  }, [dispatch]);

  useEffect(() => {
    function updateSavedFilterViews(filterViews: unknown) {
      dispatch(
        updateClosedInvoiceSavedFilterview(
          filterViews as InvoiceFilterView<ClosedInvoiceFilterParams>[]
        )
      );
    }

    function updateCurrentFilterView() {
      dispatch(updateClosedInvoiceCurrentFilterview());
    }

    setIsLoading(true);
    getSavedFilterViews('closed-invoices')
      .then((filterViews) => {
        updateSavedFilterViews(filterViews);
        updateCurrentFilterView();
        hasSavedFilterUpdated.current = true;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  const updateCurrentFilter = useCallback(
    (filter: unknown) => {
      dispatch(updateClosedInvoiceCurrentFilters(filter as ClosedInvoiceFilterParams));
    },
    [dispatch]
  );

  const ClosedInvoiceStatement = (
    <ClosedInvoicesStatements
      scroll={{
        x: 1900,
        y: 'calc(100vh - var(--app-header-height) - 330px)',
      }}
      hasActionAccess={hasActOnClosedInvoiceAccess}
      isLoading={isLoading}
      currentFilter={currentFilter as unknown as ClosedInvoicesListFilters}
      saveFilterViews={updateCurrentFilter}
      isFromInvoiceList
      currentPage={currentPage}
      handlePageChange={(page) => {
        dispatch(updateClosedCurrentPage(page));
      }}
      hasSavedFilterUpdated={hasSavedFilterUpdated.current}
    />
  );

  return (
    <Flex className="closed-invoice-list" direction="column" style={{ height: '100%' }}>
      <FlexBoxChild className="statement" style={{ padding: 'var(--space-24)' }} flex="1">
        {ClosedInvoiceStatement}
      </FlexBoxChild>
    </Flex>
  );
}

export const ClosedExtraTabContent = () => {
  const currentFilter = useSelector(closedInvoiceCurrentFilterSelector);
  const dispatch = useDispatch();
  const updateCurrentFilter = useCallback(
    (filter: unknown) => {
      dispatch(updateClosedInvoiceCurrentFilters(filter as ClosedInvoiceFilterParams));
    },
    [dispatch]
  );

  const ClosedInvoiceFilters = (
    <FilterStrip style={{ minHeight: '62px', boxShadow: 'var(--shadow-1)' }}>
      <FiltersLoader
        loading={currentFilter ? !Object.keys(currentFilter).length : true}
        gutter={[8, 8]}
      >
        <ClosedInvoiceFilterHeader
          currentFilter={currentFilter as unknown as ClosedInvoicesListFilters} //will have to be removedno
          setCurrentFilter={updateCurrentFilter}
        />
      </FiltersLoader>
    </FilterStrip>
  );

  const ClosedInvoiceFilterViewsArea = <ClosedInvoiceFilterViews />;

  return (
    <Fragment>
      {ClosedInvoiceFilterViewsArea}
      <Divider style={{ margin: 0 }} />
      {ClosedInvoiceFilters}
    </Fragment>
  );
};
