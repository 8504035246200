import { Table } from '@sinecycle/growcomponents';
import { cellComponents } from 'components/BaseComponents/GrowTable/cellComponents';
import { useCustomFieldColumns } from 'components/BaseComponents/GrowTable/hooks/useCustomFieldColumns';
import { getColumnCustomConfig } from 'components/BaseComponents/GrowTable/util';
import { Style } from 'lib/style/style';
import { useAccountLevelCustomFieldsQuery } from 'queries/custom-fields';
import { IInvoices } from 'store/customer/types';
import { CustomFieldEntityType } from 'types/entities/custom-field';
import { defaultColumnDefs } from 'util/table';
import { InvoiceStatementTableProps } from './type';

const StatementWrapper = Style('div', {
  padding: 'var(--space-24)',
  height: 'calc(-272px + 100vh)',

  '.ant-pagination': {
    marginBottom: 'var(--space-24)',
  },
  variants: {
    isFromInvoiceList: {
      true: {
        height: 'calc(-288px + 100vh)',
        padding: 0,
      },
    },
  },
});
function InvoiceStatementTable(props: InvoiceStatementTableProps) {
  const { handleSuccessCallback, columnLoading, columns, handleErrorCallBack } =
    useCustomFieldColumns<IInvoices>(
      props.columns,
      props.columnPreserveKey,
      props.currentFilter?.sort_col,
      props.currentFilter?.sort_by,
      props.currentFilter?.sort_by_custom_field
    );
  const { isLoading: cf_loading } = useAccountLevelCustomFieldsQuery(
    CustomFieldEntityType.INVOICE,
    handleSuccessCallback,
    handleErrorCallBack
  );

  const loader = props.loading || cf_loading || columnLoading;
  return (
    <StatementWrapper isFromInvoiceList={props.isFromInvoiceList}>
      <Table<IInvoices>
        loading={loader}
        gridProps={{
          rowData: loader ? undefined : props.data,
          stopEditingWhenCellsLoseFocus: true,
          columnDefs: columns,
          defaultColDef: {
            ...defaultColumnDefs,
            autoHeight: false,
            spanHeaderHeight: true,
          },
          animateRows: true,
          unSortIcon: true,
          headerHeight: 40,
          suppressMovableColumns: true,
          enableCellTextSelection: true,
          getRowId: (params) => {
            return String(params.data.id);
          },
          onBodyScroll(event) {
            if (event.direction === 'horizontal') {
              event.api.stopEditing();
            }
          },
          components: cellComponents,
          rowClassRules: props.rowClassRules,
        }}
        tablePaginationProps={{
          pageSize: 20,
        }}
        tableState={props.tableState}
        handlePageChange={props.onPageChange}
        sortMode={{
          type: 'remote',
          onSortChange(values) {
            props.onSortChange(values);
          },
        }}
        getUpdatedTableValue={props.getUpdatedTableValue}
        variants={props.variants}
        bulkActionComponents={props.bulkActions}
        quickOperations={props.quickOperations}
        enableColumnCustom={getColumnCustomConfig(props.columnPreserveKey, undefined, undefined)}
      />
    </StatementWrapper>
  );
}

export { InvoiceStatementTable };
