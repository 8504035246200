import { BreadcrumbItemType } from 'antd/lib/breadcrumb/Breadcrumb';
import produce from 'immer';
import { matchPath, useLocation } from 'lib/router';
import { findLastIndex } from 'lodash';
import { createContext, useCallback, useContext, useReducer } from 'react';
import { AppPageName, pageBacklinks } from 'router/constants/page-info';
import { sessionStore } from 'util/browser-storage';

type BreadCrumbItems = BreadcrumbItemType & { appPage: AppPageName };
interface BreadcrumbConfig {
  reset: boolean;
}

interface LayoutContextData {
  headerLayout: {
    // can add left and right components here if needed
    middleComponents: React.ReactNode[] | React.ReactNode;
  };
  headerTitle: string;
  breadcrumbs: BreadCrumbItems[];
}

enum LayoutContextAction {
  ADD_HEADER_MIDDLE_COMPONENTS = 'ADD_HEADER_MIDDLE_COMPONENTS',
  ADD_TITLE = 'ADD_TTILE',
  ADD_BREADCRUMB = 'ADD_BREADCRUMB',
  RESET_BREADCRUMB = 'RESET_BREADCRUMB',
}

type LayoutContextActionPayload =
  | {
      type: LayoutContextAction.ADD_HEADER_MIDDLE_COMPONENTS;
      payload: React.ReactNode | React.ReactNode[];
    }
  | {
      type: LayoutContextAction.ADD_TITLE;
      payload: string;
    }
  | {
      type: LayoutContextAction.ADD_BREADCRUMB;
      payload: { breadcrumb: BreadCrumbItems; config: BreadcrumbConfig };
    }
  | {
      type: LayoutContextAction.RESET_BREADCRUMB;
    };

// Initial Context State
const layoutContextIntialState: LayoutContextData = {
  headerLayout: {
    middleComponents: null,
  },
  headerTitle: '',
  breadcrumbs: [],
};

// Contexts
export const LayoutContext = createContext<LayoutContextData>(layoutContextIntialState);
export const LayoutContextDispatch =
  createContext<React.Dispatch<LayoutContextActionPayload> | null>(null);

// Reducer Function
function layoutContextReducer(
  layoutContext: LayoutContextData,
  action: LayoutContextActionPayload
) {
  switch (action.type) {
    case LayoutContextAction.ADD_HEADER_MIDDLE_COMPONENTS:
      return produce(layoutContext, (draft) => {
        draft.headerLayout.middleComponents = action.payload;
      });
    case LayoutContextAction.ADD_TITLE:
      return produce(layoutContext, (draft) => {
        draft.headerTitle = action.payload;
      });
    case LayoutContextAction.ADD_BREADCRUMB:
      return produce(layoutContext, (draft) => {
        const { breadcrumb, config } = action.payload;
        const { path, title, appPage } = breadcrumb;
        const { reset } = config;

        if (reset) {
          draft.breadcrumbs = [{ appPage, path, title }];
          return;
        }

        const pageIndex = draft.breadcrumbs.findIndex((crumb) => crumb.path === path);

        if (pageIndex !== -1) {
          const foundCrumb = draft.breadcrumbs[pageIndex];
          draft.breadcrumbs = draft.breadcrumbs.slice(0, pageIndex).concat({
            ...foundCrumb,
            title, // when we change title dynamically, just set it
          });

          return;
        }

        const lastIndex = findLastIndex(draft.breadcrumbs, (crumb) =>
          pageBacklinks(appPage).includes(crumb.appPage)
        );

        if (lastIndex === -1) {
          draft.breadcrumbs = [{ appPage, path, title }];
          return;
        }

        draft.breadcrumbs.splice(lastIndex + 1, draft.breadcrumbs.length, {
          appPage,
          path,
          title,
        });

        sessionStore.setItem('breadcrumbs', draft.breadcrumbs);
      });
    case LayoutContextAction.RESET_BREADCRUMB:
      return produce(layoutContext, (draft) => {
        draft.breadcrumbs = [];
      });
    default:
      return layoutContext;
  }
}

// Layout Hooks
export function useInitLayoutContext() {
  const location = useLocation();

  const initialBreadcumbs = (() => {
    const storedCrumbs = sessionStore.getItem<BreadCrumbItems[]>('breadcrumbs');
    if (!storedCrumbs) {
      sessionStore.setItem('breadcrumbs', []);
      return [];
    }

    const lastCrumb = storedCrumbs[storedCrumbs.length - 1];
    if (!lastCrumb) {
      sessionStore.setItem('breadcrumbs', []);
      return [];
    }
    if (!matchPath(lastCrumb.path || '', location.pathname)) {
      sessionStore.setItem('breadcrumbs', []);
      return [];
    }

    return storedCrumbs;
  })();

  const initialState: LayoutContextData = {
    ...layoutContextIntialState,
    breadcrumbs: initialBreadcumbs,
  };

  const [layoutContext, dispatch] = useReducer(layoutContextReducer, initialState);

  return [layoutContext, dispatch] as [typeof layoutContext, typeof dispatch];
}
export function useLayoutContext() {
  return useContext(LayoutContext);
}
function useLayoutContextDispatch() {
  return useContext(LayoutContextDispatch);
}

// Specific Hooks
export function usePageHeaderTitleSetter() {
  const dispatch = useLayoutContextDispatch();

  const setPageHeader = useCallback(
    (title: string) => {
      dispatch?.({ type: LayoutContextAction.ADD_TITLE, payload: title });
    },
    [dispatch]
  );

  return { setPageHeader };
}

export function useBreadCrumbs() {
  const { breadcrumbs } = useLayoutContext();
  const dispatch = useLayoutContextDispatch();

  const addBreadcrumb = useCallback(
    (breadcrumb: BreadCrumbItems, config: BreadcrumbConfig) => {
      dispatch?.({
        type: LayoutContextAction.ADD_BREADCRUMB,
        payload: { breadcrumb, config },
      });
    },
    [dispatch]
  );

  const resetBreadCrumb = useCallback(() => {
    dispatch?.({
      type: LayoutContextAction.RESET_BREADCRUMB,
    });
  }, [dispatch]);

  return { breadcrumbs, addBreadcrumb, resetBreadCrumb };
}
