import { CellRenderProps } from '@sinecycle/growcomponents';
import { Popover } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { PageLink } from 'lib/router';
import { useSelector } from 'react-redux';
import { baseCurrencySelector, localeSelector } from 'store/authentication/authentication';
import { InvoiceBasicDetails, StatementViews } from 'types/entities/invoice';
import { isInvoiceAccessible } from 'util/getIsAccessible';
import { formatAmountShort } from 'util/number-formatter';

export function InvoicesCell<T>(props: CellRenderProps<T>) {
  const { value } = props;
  const locale = useSelector(localeSelector);
  const baseCurrency = useSelector(baseCurrencySelector);
  const invoices = value as InvoiceBasicDetails[];
  if (!invoices || !invoices.length) return '-';
  const [first, second, ...Rest] = invoices;
  const restTypes = Rest.length;

  const urlSearchParam = {
    [EActivityQueryParams.CURRENT_SECTION]: StatementViews.ACTIVITIES,
    [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: StatementViews.ALL,
  } as unknown as URLSearchParams;

  function displayInvoiceText(invoice: InvoiceBasicDetails, hideAmount?: Boolean) {
    const AccessibleInvoice = (
      <PageLink
        appPage="INVOICE_DETAILS"
        pathParams={{ id: invoice.id }}
        target="_blank"
        searchParams={urlSearchParam}
      >
        <GrowText color="var(--primary-7)">{invoice.invoice_no}</GrowText>
      </PageLink>
    );
    const NonAccessibleInvoice = <GrowText>{invoice.invoice_no}</GrowText>;
    const isAccessible = isInvoiceAccessible(invoice);
    return (
      <Flex align="center" gap="--space-8" key={invoice.id}>
        {isAccessible ? AccessibleInvoice : NonAccessibleInvoice}
        {!hideAmount && (
          <GrowText>
            {formatAmountShort(
              String(invoice.balance_due_amount_base_currency),
              baseCurrency ?? 'USD',
              locale
            )}
          </GrowText>
        )}
      </Flex>
    );
  }

  const popOverContext = (
    <Flex direction="column" align="flex-start" gap="--space-8">
      <GrowText weight="semibold">Associated Invoices</GrowText>
      {invoices.map((invoice) => displayInvoiceText(invoice))}
    </Flex>
  );

  return (
    <Flex>
      <Popover
        content={popOverContext}
        placement="rightTop"
        autoAdjustOverflow
        open={restTypes > 0 ? undefined : false}
        overlayClassName="ant-popover-no-arrow"
        overlayInnerStyle={{ maxHeight: '250px', overflow: 'auto' }}
      >
        <Flex align="center" gap="--space-4">
          <Flex align="center" gap="--space-2">
            <>{displayInvoiceText(first, true)}</>
            {Boolean(second) && <> , {displayInvoiceText(second, true)}</>}
          </Flex>
          {restTypes > 0 && <GrowText color="var(--primary-7)">{`+ ${restTypes} more`}</GrowText>}
        </Flex>
      </Popover>
    </Flex>
  );
}
