import { Divider, Tabs } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { INVOICE_LIST_EVENT } from 'events/invoice-List';
import { dispatchAppEvent } from 'lib/pub-sub';
import { AccountConfigKey } from 'types/entities/account';
import { ClosedExtraTabContent, ClosedInvoiceList } from './ClosedInvoiceList';
import { DraftInvoiceFilterViews } from './DraftInvoiceList/DraftInvoiceFilterViews';
import DraftInvoiceList from './DraftInvoiceList/DraftInvoiceList';
import { OpenInvoiceList } from './OpenInvoiceList';
import { OpenInvoiceFilterViews } from './OpenInvoiceList/OpenInvoiceFilterViews';

type TabProps = React.ComponentProps<typeof Tabs>;
const tabBarStyle: React.CSSProperties = {
  padding: '0 var(--space-24)',
  background: 'white',
  margin: 0,
};

const t = {
  openInvoice: 'Open Invoice',
  closedInvoice: 'Closed Invoice',
  draftInvoice: 'Draft Invoice',
};

export enum InvoiceListTabKeys {
  OPEN_INVOICE = 'OPEN_INVOICE',
  CLOSED_INVOICE = 'CLOSED_INVOICE',
  DRAFT_INVOICE = 'DRAFT_INVOICE',
}

interface InvoiceListContainerProps {}

export function InvoiceListContainer(props: InvoiceListContainerProps) {
  const draftInvoiceEnabled = useConfig(AccountConfigKey.DRAFT_INVOICE_ENABLED);
  const draftMetricEnabled = useConfig(AccountConfigKey.DRAFT_METRIC_ENABLED);

  function onTabChange(key: string) {
    dispatchAppEvent({
      type: INVOICE_LIST_EVENT.INVOICE_LIST_TAB_SWITCH,
      payload: {
        tab: key as InvoiceListTabKeys,
      },
    });
  }

  const items: TabProps['items'] = [
    {
      key: InvoiceListTabKeys.OPEN_INVOICE,
      label: t.openInvoice,
      children: (
        <Flex direction="column">
          <OpenInvoiceFilterViews />
          <Divider style={{ margin: 0 }} />
          <OpenInvoiceList />
        </Flex>
      ),
      forceRender: false,
    },
    {
      key: InvoiceListTabKeys.CLOSED_INVOICE,
      label: t.closedInvoice,
      children: (
        <Flex direction="column">
          <ClosedExtraTabContent />
          <ClosedInvoiceList />
        </Flex>
      ),
      forceRender: false,
    },
  ];

  function computeInvoiceTabOption() {
    if (draftInvoiceEnabled || draftMetricEnabled) {
      items?.push({
        key: InvoiceListTabKeys.DRAFT_INVOICE,
        label: t.draftInvoice,
        children: (
          <Flex direction="column">
            <DraftInvoiceFilterViews />
            <Divider style={{ margin: 0 }} />
            <DraftInvoiceList />
          </Flex>
        ),
        forceRender: false,
      });
    }
    return items;
  }

  return (
    <Tabs
      tabBarStyle={tabBarStyle}
      destroyInactiveTabPane
      defaultActiveKey={`${InvoiceListTabKeys.OPEN_INVOICE}`}
      onChange={onTabChange}
      items={computeInvoiceTabOption()}
    />
  );
}
