import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TableInitialState } from '@sinecycle/growcomponents';
import { Page } from 'components/BaseComponents/Table/Pagination/TablePagination';
import { CustomersTags } from 'components/HigherOrderComponent/Tag/type';
import { head, keyBy } from 'lodash';
import { RootState } from 'reducers/rootReducer';
import { AgingSnapshot } from 'types/api/aging';
import {
  AmountInvoiceCountCustomerCount,
  AmountMaybeWithMultiCurrencySplit,
  OutstandingAmount,
} from 'types/common/multi-currency';
import { AgingData, AgingResponse, AgingSummary } from 'types/entities/aging';
import { pruneObject } from 'util/json-utils';
import { getFormattedDateForRelativeOptions, RelativeDateRange } from 'util/relative-date-range';
import {
  agingFilter,
  AgingResponseState,
  FilterViewInterface,
  SavedFilterViewInterface,
  TagsForCustomersInterface,
} from './types';

export interface AgingReduxSlice extends AgingResponse {
  snapShot: AgingSnapshot;
  tags: TagsForCustomersInterface;
  savedFilterViews: SavedFilterViewInterface<agingFilter>;
  selectAll: TableInitialState<AgingData>['selectAll'];
  selectedRows: TableInitialState<AgingData>['selectedRows'];
  agingFilter?: agingFilter;
  size: number;
}

const intialAmount: AmountMaybeWithMultiCurrencySplit = {
  value: 0,
  currency: 'USD',
};

const intitalAmountWithCount: AmountInvoiceCountCustomerCount = {
  amount: {
    value: 0,
    currency: 'USD',
  },
  customer_count: 0,
  invoice_count: 0,
};

const initialOutstandingAmount: OutstandingAmount = {
  outstanding_amount: intialAmount,
  detailed_outstanding_amount: intitalAmountWithCount,
  un_applied_credit_amount: intitalAmountWithCount,
  un_applied_payment_amount: intitalAmountWithCount,
};

const initialSnapshot: AgingSnapshot = {
  outstanding_amount: initialOutstandingAmount,
  applied_credits_amount: intitalAmountWithCount,
  un_applied_credits_amount: intitalAmountWithCount,
  applied_payments_amount: intitalAmountWithCount,
  un_applied_payments_amount: intitalAmountWithCount,
  due_amount: intitalAmountWithCount,
  overdue_amount: intitalAmountWithCount,
  disputed_invoices_amount: intitalAmountWithCount,
  ptp_amount: intitalAmountWithCount,
  escalated_invoices_amount: intitalAmountWithCount,
  flagged_invoices_amount: intitalAmountWithCount,
};

const initialState: AgingReduxSlice = {
  list: [] as AgingData[],
  snapShot: initialSnapshot,
  size: 20,
  summary: {
    total_outstanding_amount: {
      outstanding_amount: { value: 0, currency: 'USD' },
      detailed_outstanding_amount: {
        amount: { value: 0, currency: 'USD' },
        invoice_count: 0,
        customer_count: 0,
      },
    },
    total_outstanding_amount_customer_currency: {
      outstanding_amount: { value: 0, currency: 'USD' },
      detailed_outstanding_amount: {
        amount: { value: 0, currency: 'USD' },
        invoice_count: 0,
        customer_count: 0,
      },
    },
    current_due_amount: {
      amount: { value: 0, currency: 'USD' },
      invoice_count: 0,
      customer_count: 0,
    },
    overdue_amount: {
      amount: { value: 0, currency: 'USD' },
      invoice_count: 0,
      customer_count: 0,
    },
    aging_buckets: [],
  },
  tags: {} as TagsForCustomersInterface,
  savedFilterViews: {
    currentView: {},
    totalSavedViews: [{}],
  } as SavedFilterViewInterface<agingFilter>,
  total_records: 0,
  current_page: 1,
  total_pages: 0,
  selectAll: { select_all: false, selected: false },
  selectedRows: [],
  agingFilter: undefined,
};

const agingSlice = createSlice({
  name: 'agingList',
  initialState,
  reducers: {
    updateReceivablesHandler(state, action: PayloadAction<AgingResponseState>) {
      if (action.payload.aging) {
        state.list = action.payload.aging.list ?? [];
        state.summary = action.payload.aging.summary ?? ({} as AgingSummary);
        state.total_records = action.payload.aging.total_records ?? 0;
        state.current_page = action.payload.aging.current_page ?? 1;
        state.total_pages = action.payload.aging.total_pages ?? 0;
      }
    },
    updateCurrentViewAging(state, action: PayloadAction<agingFilter>) {
      const filterIds = (localStorage.getItem('filters') as any)?.AGING;
      state.agingFilter = action.payload;
      state.current_page = 1;
      state.selectedRows = [];
      state.selectAll = initialState.selectAll;
      state.savedFilterViews.totalSavedViews = state.savedFilterViews.totalSavedViews.map(
        (item) => {
          if (item.id === filterIds) {
            console.log();
            return { ...item, aging_filter: action.payload };
          }
          return item;
        }
      );
    },

    receivablesSnapshotHandler(state, action: PayloadAction<AgingSnapshot | undefined>) {
      if (!action?.payload) return;

      state.snapShot = {
        ...state.snapShot,
        ...pruneObject(action.payload),
      } as AgingSnapshot;
    },
    associateTagsToCustomerAction(state, action: PayloadAction<TagsForCustomersInterface>) {
      Array.isArray(action.payload.ref_id_list) &&
        action.payload.ref_id_list.forEach((customerId) => {
          state.list.forEach((listEntry) => {
            if (listEntry.customer.id === customerId) {
              listEntry.customer.tags = action.payload.tags;
            }
          });
        });
    },
    bulkUpdateTagsToCustomers(state, action: PayloadAction<CustomersTags[]>) {
      const customerMap = keyBy(action.payload, 'id');
      state.list.forEach((listEntry) => {
        const payloadCustomer = customerMap[listEntry.customer.id];
        if (payloadCustomer) {
          listEntry.customer.tags = payloadCustomer.tags;
        }
      });
    },
    updateCustomerHealthScoreAction(state, action: PayloadAction<{ id: number; score: number }>) {
      const customerListEntry = state?.list.find(
        (listEntry) => Number(listEntry.customer.id) === Number(action.payload.id)
      );

      if (customerListEntry) customerListEntry.customer.health_score = action.payload.score;
    },
    applyFilterHandler(state, action: PayloadAction<FilterViewInterface<agingFilter>>) {
      state.savedFilterViews.currentView = action.payload;
    },
    updateSavedFilterViewsAction(state, action: PayloadAction<FilterViewInterface<agingFilter>[]>) {
      const dateRange = [
        'due_date_range',
        'last_contacted_date_range',
        'last_responded_date_range',
        'last_payment_date_range',
        'expected_pay_date_date_range',
      ];

      let currentFilter = {} as FilterViewInterface<agingFilter>;

      const filterviewValue = JSON.parse(localStorage.getItem('filters') as string) ?? {};

      if (Array.isArray(action.payload) && action.payload.length) {
        currentFilter =
          (filterviewValue?.AGING &&
            action.payload.find((item) => item.id === filterviewValue.AGING)) ||
          action.payload[0];
      } else {
        currentFilter = {
          name: 'Assigned to me',
          aging_filter: {
            sort_col: 'CUSTOMER_NAME',
            sort_by: 'ASC',
          },
        };
      }

      state.savedFilterViews.totalSavedViews = action.payload;

      const normalizedRangeValue = {} as any;

      type attr = keyof agingFilter;

      currentFilter.aging_filter &&
        Object.keys(currentFilter.aging_filter).forEach((attribute) => {
          if (currentFilter.aging_filter) {
            if (dateRange.includes(attribute)) {
              const from =
                currentFilter.aging_filter[`${attribute.replace(/range/i, 'from')}` as attr];
              const to = currentFilter.aging_filter[`${attribute.replace(/range/i, 'to')}` as attr];
              const relativeDateIndex = currentFilter.aging_filter[attribute as attr];

              const [newFrom, newTo] = !(relativeDateIndex === 'CUSTOM_RANGE')
                ? getFormattedDateForRelativeOptions(relativeDateIndex as RelativeDateRange)
                : [from, to];

              normalizedRangeValue[`${attribute.replace(/range/i, 'from')}` as attr] = newFrom;
              normalizedRangeValue[`${attribute.replace(/range/i, 'to')}` as attr] = newTo;
              normalizedRangeValue[attribute as attr] = relativeDateIndex;
            } else {
              normalizedRangeValue[attribute as attr] =
                currentFilter.aging_filter[attribute as attr];
            }
          }
        });
      state.savedFilterViews.currentView = {
        ...currentFilter,
        aging_filter: normalizedRangeValue,
      };
    },
    updateARCurrentPage(state, action: PayloadAction<Page>) {
      state.current_page = action.payload.page;
      state.size = action.payload.size ? action.payload.size : 10;
    },

    updateSortingCurrentFilter(state, action: PayloadAction<string>) {
      const item = {
        ...JSON.parse(localStorage.getItem('filters') as any),
        AGING: action?.payload,
      };
      localStorage.setItem('filters', JSON.stringify(item));
      state.current_page = 1;
    },
    updateTableState(state, action: PayloadAction<TableInitialState<AgingData>>) {
      state.selectAll = action.payload.selectAll;
      state.selectedRows = action.payload.selectedRows;
    },
    deleteFilterViews(state, action: PayloadAction<string>) {
      const updatedView = state.savedFilterViews.totalSavedViews.filter(
        (filterViews) => filterViews.id !== action.payload
      );
      const initialView = head(updatedView);
      state.savedFilterViews.totalSavedViews = updatedView;
      if (initialView) {
        state.savedFilterViews.currentView = initialView;
      }
    },
  },
});

export const {
  updateReceivablesHandler,
  receivablesSnapshotHandler,
  applyFilterHandler,
  associateTagsToCustomerAction,
  bulkUpdateTagsToCustomers,
  updateSavedFilterViewsAction,
  updateCustomerHealthScoreAction,
  updateSortingCurrentFilter,
  updateTableState,
  updateARCurrentPage,
  updateCurrentViewAging,
  deleteFilterViews,
} = agingSlice.actions;

export default agingSlice.reducer;

export const agingSelector = (state: RootState) => state.aging;
export const snapshotSelector = createSelector(agingSelector, (aging) => aging.snapShot);
export const agingSummarySelector = createSelector(agingSelector, (aging) => aging.summary);
