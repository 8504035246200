import { Flex, FlexBoxChild } from 'components/BaseComponents/Layout/Flex';
import OpenInvoicesStatements, {
  openInvoiceStatus,
} from 'components/CustomerDetails/Body/Statements/OpenInvoicesStatements';
import FiltersLoader from 'components/FilterViews/Loader/FiltersLoaderLoader';
import FilterStrip from 'components/FilterViews/Strips/FilterStrips';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { useFormattedURLSearchParam } from 'hooks/use-formatted-url-searchparam';
import { useQuickActions } from 'hooks/use-quick-actions';
import { useLocation, usePageNavigate } from 'lib/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCollectionPeriods, getSavedCollectionPeriods } from 'services/collection-period';

import produce from 'immer';
import { getSavedFilterViews } from 'services/filter-view';
import { CollectionPeriodActions } from 'store/collection-period';
import { CollectionPeriodsHistory } from 'store/collectors-performace/type';
import {
  openInvoiceCurrentFilterSelector,
  openInvoiceQuerParams,
  resetOpenInvoiceState,
  updateOpenCurrentPage,
  updateOpenInvoiceCurrentFilters,
  updateOpenInvoiceCurrentFilterview,
  updateOpenInvoiceQuerParams,
  updateOpenInvoiceSavedFilterview,
  updateTotalCustomerCount,
} from 'store/invoice-v2/invoice';
import { openCurrentPage } from 'store/invoice-v2/open-invoice-selectors';
import { OpenInvoicesListFilters } from 'store/invoice/type';
import { InvoiceFilterView } from 'types/filter-view/common';
import { OpenInvoiceFilterParams } from 'types/filter-view/open-invoice';
import { OpenInvoiceFilterHeader } from './OpenInvoiceFilters';

export function OpenInvoiceList() {
  const hasSavedFilterUpdated = useRef(false);
  const queryParams = useFormattedURLSearchParam();
  const persistedFilter = useSelector(openInvoiceCurrentFilterSelector);
  const currentPage = useSelector(openCurrentPage);
  const openInvoiceQueryParams = useSelector(openInvoiceQuerParams);
  const navigate = usePageNavigate();
  const location = useLocation();
  const currentFilterParam = useMemo(() => {
    //since query params is not required to be persisted, storing it as a
    //temp value in store.
    return openInvoiceQueryParams ?? persistedFilter;
  }, [openInvoiceQueryParams, persistedFilter]);

  const currentFilter =
    currentFilterParam &&
    produce(currentFilterParam, (draft) => {
      draft.status = draft.status?.length ? draft.status : openInvoiceStatus;
      return draft;
    });

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const hasActOnOpenInvoiceAccess = usePrivilegeStatus([
    ERolesAndPrivileges.ACT_OPEN_INVOICES_INVOICE_LIST,
  ]);

  const {
    selectAll,
    setSelectAll,
    setQuickActivitiesCallback,
    setQuickActionsCallback,
    setTotalRecords,
    QuickActions,
    action,
  } = useQuickActions({ currentFilter, queryKey: ['open-invoice-list'] });

  function updateQueryParams() {
    if (queryParams) {
      dispatch(updateOpenInvoiceQuerParams(queryParams as OpenInvoiceFilterParams));
    }
  }

  function handleGetSaveCollectionPeriods(optionIds: number[], persistedIds?: number[]) {
    if (persistedIds && Boolean(persistedIds.length)) {
      const filterIds: number[] = persistedIds.filter((d) => !optionIds.includes(d));
      if (filterIds.length) {
        getSavedCollectionPeriods(filterIds)
          .then((data) => {
            dispatch(
              CollectionPeriodActions.updateCollectionPeriodFilters({
                key: 'invoice-list',
                filter: data,
              })
            );
            hasSavedFilterUpdated.current = true;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }

  const getSavedCollectionPeriodsCallBack = useCallback(handleGetSaveCollectionPeriods, [dispatch]);

  function handleSuccessResolve(data: CollectionPeriodsHistory[]) {
    dispatch(
      CollectionPeriodActions.initializeCollectionPeriodFilters({
        key: 'invoice-list',
        filter: data,
      })
    );
    if (currentFilter?.collection_period_ids?.length) {
      const savedFilterIds = currentFilter?.collection_period_ids?.filter(
        (filterId) => filterId !== -1
      );
      const optionIds = data.map((option) => option.id);
      getSavedCollectionPeriodsCallBack(optionIds, savedFilterIds);
    } else {
      hasSavedFilterUpdated.current = true;
    }
  }

  const successResolveCallBack = useCallback(handleSuccessResolve, [
    currentFilter?.collection_period_ids,
    dispatch,
    getSavedCollectionPeriodsCallBack,
  ]);

  function handleResolveReject(e: any) {
    console.log(e);
  }
  function fetchCollectionPeriods() {
    if (!hasSavedFilterUpdated.current) {
      getCollectionPeriods().then(successResolveCallBack).catch(handleResolveReject);
    }
  }
  useEffect(updateQueryParams, [dispatch, queryParams]);
  useEffect(() => {
    return () => {
      //replacing the query params when shifting to another tab
      if (queryParams) {
        dispatch(updateOpenInvoiceQuerParams(undefined));
        //replacing history to remove query param when changing tabs.
        if (location.pathname.match('/invoice-list')) {
          navigate({ appPage: 'INVOICE_LIST' });
        }
      }

      dispatch(resetOpenInvoiceState());
    };
  }, [dispatch, navigate, queryParams, location.pathname]);

  useEffect(() => {
    function updateSavedFilterViews(filterViews: unknown) {
      dispatch(
        updateOpenInvoiceSavedFilterview(
          filterViews as InvoiceFilterView<OpenInvoiceFilterParams>[]
        )
      );
    }

    function updateCurrentFilterView() {
      dispatch(updateOpenInvoiceCurrentFilterview());
    }

    setIsLoading(true);
    getSavedFilterViews('open-invoices')
      .then((filterViews) => {
        updateSavedFilterViews(filterViews);
        updateCurrentFilterView();
        hasSavedFilterUpdated.current = true;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  const updateCurrentFilter = useCallback(
    (filter: unknown) => {
      if (queryParams) {
        dispatch(updateOpenInvoiceQuerParams(filter as OpenInvoiceFilterParams));
      } else {
        dispatch(
          updateOpenInvoiceCurrentFilters({
            ...(filter as OpenInvoiceFilterParams),
          } as OpenInvoiceFilterParams)
        );
      }
    },
    [dispatch, queryParams]
  );

  useEffect(fetchCollectionPeriods, [successResolveCallBack]);

  const OpenInvoiceStatement = (
    <OpenInvoicesStatements
      scroll={{
        x: 1900,
        y: 'calc(100vh - var(--app-header-height) - var(--open-invoice-fixed-contents-height-invoice-details))',
      }}
      isFromInvoiceList
      hasActionAccess={hasActOnOpenInvoiceAccess} //should be moved inside open invoice statements as well
      isLoading={isLoading}
      currentFilter={currentFilter as unknown as OpenInvoicesListFilters}
      invoiceStatusList={[]}
      saveFilterViews={updateCurrentFilter}
      setQuickAction={setQuickActivitiesCallback}
      setAction={setQuickActionsCallback}
      selectAll={selectAll}
      setSelectAll={setSelectAll}
      setTotalRecords={setTotalRecords}
      setCustomerTotalRecords={(records) => {
        dispatch(updateTotalCustomerCount(records as number));
      }}
      currentPage={currentPage}
      handlePageChange={(page) => {
        dispatch(updateOpenCurrentPage(page));
      }}
      hasFilterViewLoaded={hasSavedFilterUpdated.current}
      action={action}
    />
  );

  return (
    <Flex className="open-invoice-list" direction="column" style={{ height: '100%' }}>
      <FilterStrip style={{ minHeight: '62px', boxShadow: 'var(--shadow-1)' }}>
        <FiltersLoader loading={!currentFilterParam} gutter={[8, 8]}>
          <OpenInvoiceFilterHeader
            currentFilter={currentFilterParam as unknown as OpenInvoicesListFilters} //will have to be removedno
            setCurrentFilter={updateCurrentFilter}
          />
        </FiltersLoader>
      </FilterStrip>
      <FlexBoxChild className="statement" style={{ padding: 'var(--space-24)' }} flex="1">
        {OpenInvoiceStatement}
      </FlexBoxChild>
      {QuickActions}
    </Flex>
  );
}
