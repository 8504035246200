import { GrowTypography, customerNameFormatter } from '@sinecycle/growcomponents';
import { useScrollIntoView } from 'hooks/use-scroll-into-view';
import { CustomerTreeNode } from 'types/entities/customer';
import { DisplayAuthorisedName } from './ParentChild';
interface NodeTitleRendererProps {
  node: CustomerTreeNode;
  customerId: number;
}
export default function NodeTitleRenderer(props: NodeTitleRendererProps) {
  const { node, customerId } = props;
  const ref = useScrollIntoView();
  const customerNameText = customerNameFormatter({ name: node.customer_name });
  const isSelectedNode = customerId === node.customer_id;
  const CustomerNameWithLink = (
    <GrowTypography.Text
      strong={isSelectedNode}
      ref={isSelectedNode ? ref : undefined}
      ellipsis={{ tooltip: customerNameText }}
      style={{ lineHeight: 'var(--lh-24)', maxWidth: '250px' }}
    >
      <DisplayAuthorisedName
        name={node.customer_name}
        id={node.customer_id}
        authorised={node.is_accessible && !isSelectedNode}
      />
    </GrowTypography.Text>
  );
  return <>{CustomerNameWithLink}</>;
}
