import { PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { ClosedInvoiceFilterParams } from 'types/filter-view/closed-invoice';
import {
  PersistedFilterKeys,
  PersistedFilterProps,
  PersistedFilterViews,
} from 'types/filter-view/common';
import { localStore, sessionStore } from 'util/browser-storage';
import { intitalStateProps } from './invoice';
import { filterNormalizer } from './util';

const { getItem: getFromLocalStorage, setItem: setToLocalStorage } = localStore;
const { getItem: getFromSessionStorage, setItem: setToSessionStorage } = sessionStore;

const closedInvoiceActions = {
  updateClosedInvoiceSavedFilterview(
    state: intitalStateProps,
    action: PayloadAction<intitalStateProps['closedInvoiceSavedFilterviews']>
  ) {
    const locallyPersistedFilterIds = getFromLocalStorage<PersistedFilterProps>('filters');
    const sessionStore = getFromSessionStorage<PersistedFilterViews<ClosedInvoiceFilterParams>>(
      PersistedFilterKeys.CLOSED_INVOICES
    );

    const isFilterViewPresent =
      action.payload.findIndex((item) => item.id === locallyPersistedFilterIds?.CLOSED_INVOICES) >
      -1;

    if (!isFilterViewPresent && locallyPersistedFilterIds && sessionStore) {
      //removing both the entries from browser store
      delete sessionStore[`${locallyPersistedFilterIds?.CLOSED_INVOICES}`];
      delete locallyPersistedFilterIds.CLOSED_INVOICES;

      setToLocalStorage('filters', locallyPersistedFilterIds);
      setToSessionStorage(PersistedFilterKeys.CLOSED_INVOICES, sessionStore);
    }
    state.closedInvoiceSavedFilterviews = action.payload;
  },

  updateClosedInvoiceCurrentFilterview(
    state: intitalStateProps,
    action: PayloadAction<{ id?: string; reset?: boolean } | undefined>
  ) {
    state.closedCurrentPage = 1;
    const locallyPersistedFilterIds = getFromLocalStorage<PersistedFilterProps>('filters') ?? {};
    const sessionStore = getFromSessionStorage<PersistedFilterViews<ClosedInvoiceFilterParams>>(
      PersistedFilterKeys.CLOSED_INVOICES
    );

    if (action && action.payload?.id && locallyPersistedFilterIds) {
      const item: PersistedFilterProps = {
        ...locallyPersistedFilterIds,
        CLOSED_INVOICES: action.payload.id,
      };

      setToLocalStorage('filters', item);
    }

    const savedFilterViews = state.closedInvoiceSavedFilterviews;
    const currentFilterViewId =
      action.payload?.id ?? locallyPersistedFilterIds?.CLOSED_INVOICES ?? savedFilterViews[0].id;
    const currentFilter =
      savedFilterViews.find((filterView) => {
        return filterView.id === currentFilterViewId;
      }) ?? savedFilterViews[0];

    //When applying filter, giving preference to session store
    const sessionFilters = sessionStore && sessionStore[`${currentFilterViewId}`];
    const filters = action.payload?.reset
      ? currentFilter.invoice_filter
      : sessionFilters ?? currentFilter.invoice_filter;
    state.closedInvoiceCurrentFilterView = {
      ...currentFilter,
      invoice_filter: filterNormalizer(filters),
    };
  },

  updateClosedInvoiceCurrentFilters(
    state: intitalStateProps,
    action: PayloadAction<ClosedInvoiceFilterParams>
  ) {
    state.closedCurrentPage = 1;
    const sessionStore =
      getFromSessionStorage<PersistedFilterViews<ClosedInvoiceFilterParams>>(
        PersistedFilterKeys.CLOSED_INVOICES
      ) ?? {};

    const currentFilter = action.payload;
    const filterViewId = state.closedInvoiceCurrentFilterView?.id;
    //preventing duplicate calls through this.
    const shouldUpdate =
      Boolean(Object.keys(currentFilter).length) &&
      !isEqual(currentFilter, state.closedInvoiceCurrentFilterView?.invoice_filter);

    if (shouldUpdate) {
      const updatedState = {
        ...state.closedInvoiceCurrentFilterView,
        invoice_filter: currentFilter,
      } as intitalStateProps['closedInvoiceCurrentFilterView'];
      state.closedInvoiceCurrentFilterView = updatedState;

      //updating the session storage while updating the currentfilter
      if (sessionStore) {
        sessionStore[`${filterViewId}`] = currentFilter;

        setToSessionStorage(PersistedFilterKeys.CLOSED_INVOICES, sessionStore);
      }
    }
  },
  resetClosedInvoiceState(state: intitalStateProps) {
    state.closedInvoiceSavedFilterviews = [];
    state.closedInvoiceCurrentFilterView =
      {} as intitalStateProps['closedInvoiceCurrentFilterView'];
  },
  updateClosedCurrentPage(state: intitalStateProps, action: PayloadAction<number>) {
    state.closedCurrentPage = action.payload;
  },
  resetClosedCurrentPage(state: intitalStateProps) {
    state.closedCurrentPage = 1;
  },
};

export { closedInvoiceActions };
