import { FilterView, FilterViews as GrowFilterViews } from '@sinecycle/growcomponents';
import { notify } from 'components/BaseComponents/Notifications';
import head from 'lodash/head';
import { useCallback } from 'react';
import { useDeleteFilterView, useSaveFilterView } from 'services/filter-view';
import { localStore, sessionStore } from 'util/browser-storage';
import { t } from './constants';
import { FilterViewsProps } from './type';
import localStorageInitialization from './utils/localStorageInitalization';
import sessionStorageInitialization from './utils/sessionStorageInitalization';

function FilterViews<T, M>(props: Readonly<FilterViewsProps<T, M>>) {
  const {
    totalSavedViews,
    entity,
    deleteFilterViewCallBack,
    saveFilterViewCallBack,
    updateFilterViewCallBack,
    filterType,
    saveAsFilterViewCallBack,
    updatedFilterValue,
    storageKey,
    currentView,
    hideIcons,
    components,
    filterViewLoading,
    onResetFilterViewCallBack,
  } = props;

  const { setItem: setToLocalStorage } = localStore;
  const { getItem: getFromSessionStorage, setItem: setToSessionStorage } = sessionStore;

  const { mutateAsync: saveView, isLoading } = useSaveFilterView<M>();
  const { mutateAsync: deleteView } = useDeleteFilterView();

  localStorageInitialization({ storageKey, id: currentView.id });
  sessionStorageInitialization({ storageKey, id: currentView.id, updatedFilterValue });

  function handleSessionDelete(currentViewId?: string | number) {
    const viewId = String(currentViewId);
    if (viewId && storageKey) {
      const sessionFilter = getFromSessionStorage(`${storageKey}_FILTERS`) as any;
      if (Object.keys(sessionFilter).length) {
        delete sessionFilter[`${viewId}`];
        setToSessionStorage(`${storageKey}_FILTERS`, sessionFilter);
      }
    }
  }

  function updateCurrentFilterView(data?: FilterView<M>, reset?: boolean) {
    if (!data) return;
    const id = data.id;
    const updateCurrenFilterView = totalSavedViews.find((savedViews) => savedViews.id === id);
    handleFilterViewLocalStorage(id);
    if (reset) {
      onResetFilterViewCallBack &&
        onResetFilterViewCallBack(updateCurrenFilterView as unknown as T);
    } else {
      updateFilterViewCallBack && updateFilterViewCallBack(updateCurrenFilterView as unknown as T);
    }
  }

  const handleFilterViewLocalStorage = useCallback(
    (id: string | number) => {
      storageKey && setToLocalStorage(storageKey, String(id));
    },
    [setToLocalStorage, storageKey]
  );

  function onSave(filterData: Partial<FilterView<M>>) {
    const { name, description } = filterData;
    const config: Partial<FilterView<M>> = {
      [filterType]: { ...updatedFilterValue },
      name: name,
      description: description,
      ...(filterData.id !== undefined && { id: filterData.id }),
    };
    return saveView({ data: config, type: entity ? entity : '' })
      .then((data) => {
        notify.success(`'${data.name}' Filter saved successfully`);
        if (filterData.id) {
          saveFilterViewCallBack && saveFilterViewCallBack(data as T);
        } else {
          saveAsFilterViewCallBack && saveAsFilterViewCallBack(data as T);
        }
        handleFilterViewLocalStorage(data.id);
        return data;
      })
      .catch((e) => {
        notify.error(t.err_message.cant_save);
        console.log(e);
      });
  }

  function onDelete(id?: string | number) {
    if (!id) return;
    return deleteView(String(id))
      .then(() => {
        notify.success('Filter View deleted successfully');
        deleteFilterViewCallBack && deleteFilterViewCallBack(id as string);
        handleSessionDelete(id);
        updateFilterViewCallBack && updateFilterViewCallBack(head(totalSavedViews) as unknown as T);
        storageKey && setToLocalStorage(storageKey, String(head(totalSavedViews)?.id));
      })
      .catch((e) => {
        notify.error(t.err_message.cant_delete);
        console.log(e);
      });
  }

  return (
    <GrowFilterViews<M>
      filterList={totalSavedViews}
      currentSelectedView={currentView}
      filterKey={filterType}
      filterListLoading={filterViewLoading || !totalSavedViews.length}
      updatedFilterValue={updatedFilterValue}
      onSelect={updateCurrentFilterView}
      onSave={onSave as (view: Partial<FilterView<M>>) => void | Promise<FilterView<M>>}
      onDelete={onDelete}
      onReset={(data) => updateCurrentFilterView(data, true)}
      onSaveLoading={isLoading}
      sanitizeObject
      disableActions={hideIcons}
      components={components}
      colSpan={16}
    />
  );
}

export { FilterViews };
