import { GrowCurrencyText, GrowNumberFormat, GrowTypography } from '@sinecycle/growcomponents';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { ChartTooltip } from 'components/Common/Charts/types';
import { head } from 'lodash';
import { getPercentage } from 'util/number-formatter';
import { AgingBucketData } from '../type';

const t = {
  Invoices: 'Invoices',
  Days: 'days',
  balance: 'Balance',
  customers: 'Customers',
};
interface AgingBucketTooltipContent extends ChartTooltip {
  data: AgingBucketData;
}

interface TooltipProps {
  content: AgingBucketTooltipContent[];
  locale?: string;
  hidePercentage?: Boolean;
  showCustomerCount?: Boolean;
  customTitle?: string;
}

function Tooltip(props: TooltipProps) {
  const contentHead = head(props.content);
  const value = contentHead ? contentHead.data.value : 0;
  const totalAmount = contentHead ? contentHead.data.totalAmount : 0;
  const invoiceCount = contentHead ? contentHead.data.invoices : 0;
  const customerCount = contentHead ? contentHead.data.customersCount : 0;
  const currency = contentHead ? contentHead.data.currency : 'USD';
  const type = contentHead ? contentHead.data.type : '';
  const percentage = getPercentage(value, totalAmount);
  const age = contentHead ? contentHead.data.age : 0;
  const CustomTitle = (
    <GrowTypography.Text strong fs="12" style={{ color: 'var(--gray-8)' }}>
      {props.customTitle}
    </GrowTypography.Text>
  );
  const invoices = (
    <Flex direction="column">
      <GrowTypography.Text fs="12" style={{ color: 'var(--gray-7)' }}>
        {t.Invoices}
      </GrowTypography.Text>
      <GrowNumberFormat
        value={invoiceCount}
        options={{ locale: props.locale }}
        textProps={{ fs: '12' }}
      />
    </Flex>
  );
  const CustomersCount = (
    <Flex direction="column">
      <GrowTypography.Text fs="12" style={{ color: 'var(--gray-7)' }}>
        {t.customers}
      </GrowTypography.Text>
      <GrowNumberFormat
        value={customerCount}
        options={{ locale: props.locale }}
        textProps={{ fs: '12' }}
      />
    </Flex>
  );
  const amount = (
    <Flex direction="column">
      <GrowTypography.Text style={{ color: 'var(--gray-7)' }}>{t.balance}</GrowTypography.Text>
      <GrowCurrencyText
        amount={value}
        options={{
          currency: currency,
          locale: props.locale,
        }}
      />
    </Flex>
  );

  const tooltipHeader = (
    <Flex direction="column" gap="var(--space-4)">
      <Flex align="center" gap="var(--space-4)">
        <div
          style={{
            background: type,
            width: 10.5,
            height: 10.5,
          }}
        />
        <GrowTypography.Text fs="12" style={{ color: 'var(--gray-7)' }}>
          {`${age} ${t.Days}`}
        </GrowTypography.Text>
      </Flex>
      {props.hidePercentage ? (
        <></>
      ) : (
        <GrowTypography.Text fs="12">{percentage}</GrowTypography.Text>
      )}
    </Flex>
  );

  const tooltipBody = (
    <Flex direction="column" gap="var(--space-12)">
      {amount}
      {props.showCustomerCount ? CustomersCount : <></>}
      {invoices}
    </Flex>
  );

  return (
    <Flex
      direction="column"
      gap="var(--space-12)"
      className="custom-tooltip"
      style={{ zIndex: '20' }}
    >
      {props.customTitle ? CustomTitle : <></>}
      {tooltipHeader}
      {tooltipBody}
    </Flex>
  );
}

export default Tooltip;
