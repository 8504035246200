import { Divider } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { FilterViews } from 'components/FilterViews';
import { DashboardTab } from '..';
import { DashboardFilterStrip } from '../DashboardFilter';
import { useDashboardFilter } from '../DashboardFilter/hooks/useDashboardFilter';
import useDashboardFilterView from '../DashboardFilter/hooks/useDashboardFilterView';
import {
  DashboardFilters,
  DashBoardFilterView,
} from '../DashboardFilter/reducers/filterValuesReducer';
import DashboardSkeleton from '../SkeletonLoader';
import { TabBodyStyle } from '../style';
import { SnapshotView } from './Snapshot';

function SnapShotDashboard(props: {}) {
  const dashboardFilterStripProps = useDashboardFilter();
  const FilterStrips = <DashboardFilterStrip {...dashboardFilterStripProps} />;
  const { totalSavedViews, currentFilter, setCurrentFilter } = useDashboardFilterView(
    DashboardTab.SNAPSHOT
  );
  if (!totalSavedViews) {
    return <DashboardSkeleton />;
  }

  const currentFilterCondition = currentFilter && Boolean(Object.keys(currentFilter).length);

  function handleUpdateFilterView(filterViews: unknown) {
    const filter = filterViews as unknown as DashBoardFilterView;
    setCurrentFilter(filter.dashboard_filter);
  }

  const FilterViewStrip = (
    <FilterViews<DashBoardFilterView, Partial<DashboardFilters>>
      currentView={totalSavedViews[0] as DashBoardFilterView}
      totalSavedViews={totalSavedViews as DashBoardFilterView[]}
      filterType="dashboard_filter"
      updateFilterViewCallBack={handleUpdateFilterView}
      hideIcons
    />
  );

  return (
    <Flex direction="column" className="scrollable-tab-content">
      <Flex.Child>
        {FilterViewStrip}
        <Divider style={{ margin: 0 }} />
        {FilterStrips}
      </Flex.Child>
      {currentFilterCondition && (
        <Flex.Child style={TabBodyStyle}>
          <SnapshotView
            snapshotFilterParams={{ ...dashboardFilterStripProps.filterValues, ...currentFilter }}
          />
        </Flex.Child>
      )}
    </Flex>
  );
}

export default SnapShotDashboard;
