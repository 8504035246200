import { useMutation, useQuery } from '@tanstack/react-query';
import { notify } from 'components/BaseComponents/Notifications';
import { HTTPError } from 'ky';
import { getCustomerOpenInvoices } from 'services/aging';
import { getCollectionStrategies } from 'services/collection-strategy';
import {
  CustomerRegionUpdateProps,
  WorkflowStatusUpdate,
  getCreditTerms,
  getCustomerDirectChild,
  getCustomerFamilyTree,
  getCustomersDropdownList,
  getCustomersPaymentStatements,
  setCollectionStrategyV2,
  updateCustomerInformation,
  updateCustomerRegion,
  updateWorkflowStatus,
} from 'services/customers';
import { IPaymentStatmentsQueryParams } from 'store/customer/types';
import { PaymentListData } from 'store/payments/type';
import { UpdateCustomerInformationParams } from 'types/api/customer';
import { getCustomerWorkflowMapping } from '../../services/customers';

interface UpdateCustomerInfoProps {
  id: number;
  params: UpdateCustomerInformationParams;
}
interface usePaymentStatementProps {
  config: { id: number; params: IPaymentStatmentsQueryParams };
  onSuccessCallBack: (data: PaymentListData) => void;
}
const HALF_DAY = 12 * 60 * 60 * 1000;
const ONE_MINUTE = 60 * 1000;
const t = {
  deniedText: 'Access denied.',
  deniedDesc: 'You do not have the required permissions to view the complete family tree',
};

export default function useUpdateCustomerInfo() {
  return useMutation({
    mutationFn: (params: UpdateCustomerInfoProps) => {
      return updateCustomerInformation(params.id, params.params);
    },
    onError: () => {},
  });
}

export function useGetCustomerDropdownList(unscoped?: boolean) {
  return useQuery({
    queryKey: ['customer-dropdown-list'],
    queryFn: () => getCustomersDropdownList({ size: 15, un_scoped: unscoped }),
  });
}

export function useGetSelectedCustomerDropdownList(selectedValues?: number[]) {
  return useQuery({
    queryKey: ['selected-customer-dropdown-list'],
    queryFn: () => getCustomersDropdownList({ customer_ids: selectedValues?.toString() }),
    enabled: Boolean(selectedValues?.length),
  });
}
export function useGetCustomerDropdownListSearch(unscoped?: boolean) {
  return useMutation({
    mutationFn: (text: string) => {
      return getCustomersDropdownList({ text, un_scoped: unscoped });
    },
  });
}

export function useCustomerOpenInvoices() {
  return useMutation({
    mutationFn: (ids: number[]) => getCustomerOpenInvoices({ ids }),
  });
}

export function usePaymentStatementsQuery(props: usePaymentStatementProps) {
  return useQuery({
    queryKey: ['payment-statements', props.config],
    queryFn: () => getCustomersPaymentStatements(props.config.id, props.config.params),
    cacheTime: 0,
    staleTime: 0,
    onSuccess(data) {
      props.onSuccessCallBack(data);
    },
  });
}

export function useGetCollectionStrategiesQuery() {
  return useQuery({
    queryKey: ['collection-followup'],
    queryFn: () => getCollectionStrategies(),
    cacheTime: ONE_MINUTE,
    staleTime: ONE_MINUTE,
  });
}

export function useGetCustomerWorkflowMappingQuery(customerId: number) {
  return useQuery({
    queryKey: ['workflow-mapping'],
    queryFn: () => getCustomerWorkflowMapping(customerId),
    cacheTime: 0,
    staleTime: 0,
  });
}

export function useSetCollectionStrategyV2Mutation(customerId: number) {
  return useMutation({
    mutationFn: (strategyId?: number) => setCollectionStrategyV2(customerId, strategyId),
    onSuccess() {},
    onError() {},
  });
}

export function useUpdateWorkflowStatusMutation() {
  return useMutation({
    mutationFn: (props: WorkflowStatusUpdate) => updateWorkflowStatus(props),
    onSuccess() {},
    onError() {},
  });
}

export function useUpdateCustomerRegion() {
  return useMutation({
    mutationFn: (props: CustomerRegionUpdateProps) => updateCustomerRegion(props),
    onSuccess() {},
  });
}

export function useGetCreditTerms() {
  return useQuery({
    queryKey: ['credit-terms'],
    queryFn: () => getCreditTerms(),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
  });
}

export function useCustomerFamilyTree(id: number) {
  return useQuery({
    queryKey: ['customer-family-tree'],
    queryFn: () => getCustomerFamilyTree(id),
  });
}

export function useGetCustomerDirectChild() {
  return useMutation({
    mutationKey: ['customer-direct-child'],
    mutationFn: (id: number) => getCustomerDirectChild(id),
    onSuccess() {},
    onError: (err: HTTPError) => {
      if (err?.response.status === 403) {
        notify.error(t.deniedText, { description: t.deniedDesc });
      }
    },
    retry: false,
  });
}
